@import './styling/main.scss';
.App {
  width: 1920 * $s;
  height: 1080 * $s;
  overflow: hidden;
  font-family: SamsungOneUI-600;
  .news-tv {
    // background: linear-gradient(180deg, rgba(6, 24, 44, 0.8), #000000);
    width: 1920 * $s;
    height: 1080 * $s;
    position: relative;
    .video-player {
      position: absolute;
      top: 0 * $s;
      left: 0 * $s;
    }
    .channel-drawer {
      // position: absolute;
      // top: 560 * $s;
      // left: 0 * $s;
    }
  }
}
