@font-face {
  font-family: SamsungOneUI-200;
  src: url('../assets/fonts/SamsungOneUI-200.ttf');
}

@font-face {
  font-family: SamsungOneUI-300;
  font-weight: 300;
  src: url('../assets/fonts/SamsungOneUI-300.ttf');
}

@font-face {
  font-family: SamsungOneUI-400;
  font-weight: 400;
  src: url('../assets/fonts/SamsungOneUI-400.ttf');
}

@font-face {
  font-family: SamsungOneUI-500;
  font-weight: 500;
  src: url('../assets/fonts/SamsungOneUI-500.ttf');
}

@font-face {
  font-family: SamsungOneUI-600;
  font-weight: 600;
  src: url('../assets/fonts/SamsungOneUI-600.ttf');
}

@font-face {
  font-family: SamsungOneUI-700;
  font-weight: 700;
  src: url('../assets/fonts/SamsungOneUI-700.ttf');
}
