@import '../../styling/main.scss';
.tooltip {
  position: absolute;
  // top: 48 * $s;
  left: 1248 * $s;
  width: 624 * $s;
  height: 200 * $s;
  border-radius: 16px;
  border: 2 * $s solid #0A1450;
  background: #0A1450;
  opacity: 0;
  &.visible {
    animation: tooltip-up 300ms cubic-bezier(0.3, 0, 0.15, 1) 600ms forwards;
  }
  &.hide {
    animation: tooltip-down 300ms cubic-bezier(0.3, 0, 0.15, 1) forwards;
  }
  .tooltip-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    padding-left: 48 * $s;
    padding-right: 48 * $s;
    .tooltip-img {
      position: relative;
      img {
        width: 104 * $s;
        height: 104 * $s;
      }
    }
    .tooltip-text-container {
      width: 368 * $s;
      //   height: 112 * $s;
      font-size: 24 * $s;
      font-weight: 400;
      line-height: 40 * $s;
      .title-text {
        // color: var(--cc-basic-1100, #FFF);
        font-family: SamsungOne;
        font-size: 24 * $s;
        font-style: normal;
        font-weight: 800;
        line-height: 40 * $s; /* 166.667% */
        padding-bottom: 10 * $s;
      }
      .subtitle-text{
        // color: var(--cc-basic-1100, #FFF);
        /* T24/400 */
        font-family: SamsungOne;
        font-size: 24 * $s;
        font-style: normal;
        font-weight: 400;
        line-height: 40 * $s; /* 166.667% */
      }
    }
  }
}
@keyframes tooltip-up {
  0% {
    opacity: 0;
    top: -80 * $s;
  }
  100% {
    opacity: 1;
    top: 48 * $s;
  }
}
@keyframes tooltip-down {
  0% {
    // opacity: 1;
  }
  100% {
    opacity: 0;
  }
}