@import '../../styling/main.scss';

.program-info {
  position: absolute;
  top: 64 * $s;
  left: 96 * $s;
  width: 696 * $s;
  height: 172 * $s;
  font-family: SamsungOneUI-500;
  &.visible {
    animation: info-up 700ms cubic-bezier(0.3, 0, 0.15, 1) forwards;
  }
  &.hide {
    animation: info-down 700ms cubic-bezier(0.3, 0, 0.15, 1) forwards;
  }
  .channel-info {
    display: flex;
    align-items: center;
    height: 48 * $s;
    // gap: 16 * $s;
    .channel-icon {
      width: 40 * $s;
      height: 40 * $s;
      margin-right: 16 * $s;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .channel-name {
      font-size: 28 * $s;
      font-style: normal;
      // font-family: SamsungOneUI-500;
      // font-weight: 500;
      line-height: 48 * $s;
    }
  }
  .show-name {
    width: 100%;
    max-height: 128 * $s;
    font-size: 48 * $s;
    font-style: normal;
    // font-weight: 700;
    line-height: 64 * $s;
    font-family: SamsungOneUI-700;
  }
  .show-duration {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 60 * $s;
    .time-wrapper {
      display: flex;
      align-items: center;
      height: 48 * $s;
      // gap: 20 * $s;
      font-size: 28 * $s;
    }
    .live-icon {
      width: 64 * $s;
      height: 28 * $s;
      margin-right: 10 * $s;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .progress-bar {
      position: relative;
      width: 295 * $s;
      .bar {
        position: absolute;
        width: 295 * $s;
        height: 4 * $s;
        border-radius: 8 * $s;
        background-color: rgba(255, 255, 255, 0.7);
      }
      .bar-fill {
        position: absolute;
        height: 4 * $s;
        // width: 100 * $s;
        border-radius: 8 * $s 0 * $s 0 * $s 8 * $s;
        background: #f75157;
      }
    }
  }
}
@keyframes info-up {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes info-down {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
