@import '../../styling/main.scss';

.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  .loader {
    width: 200 * $s;
    height: 200 * $s;
  }
}
