@import '../../styling/main.scss';
$loader-bg: rgb(49, 44, 44);

.video-player {
  width: 1920 * $s;
  height: 1080 * $s;
  position: absolute;

  .video {
    width: inherit;
    height: inherit;
    position: absolute;
    z-index: 1;
  }
}