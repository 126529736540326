@import '../../styling/main.scss';

.basic-popup {
  position: absolute;
  width: 1920 * $s;
  height: 1080 * $s;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 20;
  .basic-popup-wrapper {
    width: 952 * $s;
    height: 408 * $s;
    border-radius: 12 * $s;
    border: 1 * $s solid rgba(255, 255, 255, 0.9);
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .basic-popup-header {
      display: flex;
      justify-content: center;
      margin-top: 24 * $s;
      .title {
        font-size: 36 * $s;
        font-style: normal;
        font-weight: 600;
        line-height: 56 * $s;
      }
    }
    .basic-popup-message {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 872 * $s;
      height: 144 * $s;
      color: rgba(255, 255, 255, 0.9);

      text-align: center;
      font-family: SamsungOneUI;
      font-size: 28 * $s;
      font-style: normal;
      font-weight: 500;
      line-height: 48 * $s;
    }
    .basic-popup-buttons {
      display: flex;
      //   justify-content: space-around;
      .buttonItem {
        width: 312 * $s;
        height: 72 * $s;
        font-size: 28 * $s;
        font-style: normal;
        font-weight: 700;
        line-height: 72 * $s;
        border-radius: 16 * $s;
        border: 2 * $s solid rgba(255, 255, 255, 0.2);
        background: rgba(0, 0, 0, 0.4);
        text-align: center;
        margin-bottom: 40 * $s;
        margin-left: 10 * $s;
        margin-right: 10 * $s;
        &.focused {
          background-color: white;
          color: black;
        }
      }
    }
  }
}
