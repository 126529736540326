@import '../../styling/main.scss';
.loginPage-wrapper {
  position: relative;
  width: 1920 * $s;
  height: 1080 * $s;
  overflow: hidden;
  font-family: SamsungOneUI-600;
  background-image: url('../../assets/Snews_Interstitial.jpg');
  background-size: cover;
  .loginPage {
    width: 100%;
    height: 100%;
    background: linear-gradient(76deg, #181818 34.81%, rgba(0, 0, 0, 0.3) 100%);
    .logo {
      position: absolute;
      top: 312 * $s;
      left: 176 * $s;
      width: 416 * $s;
      height: 44 * $s;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text-info {
      position: relative;
      top: 410 * $s;
      left: 176 * $s;
      width: 720 * $s;
      .text {
        width: 100%;
        font-size: 56 * $s;
        font-style: normal;
        font-weight: 700;
        line-height: 80 * $s;
        padding-bottom: 20 * $s;
        color: #fff;
      }
      .sub-text {
        width: 95%;
        position: relative;
        color: rgba(255, 255, 255, 0.8);
        font-size: 28 * $s;
        font-style: normal;
        font-weight: 400;
        line-height: 48 * $s;
      }
    }
    .login-btn {
      position: absolute;
      top: 698 * $s;
      left: 176 * $s;
      width: 312 * $s;
      height: 72 * $s;
      text-align: center;
      line-height: 72 * $s;
      background: linear-gradient(262deg, #dd3468 16.68%, #ea5634 94.65%);
      font-size: 24 * $s;
      font-style: normal;
      font-weight: 400;
      color: #fff;
      border-radius: 16 * $s;
    }
  }
}
