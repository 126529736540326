@import '../../styling/main.scss';
$img_w: 406 * $s;
$img_h: 228 * $s;

$border_w: 16 * $s; // 2 times border
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: SamsungOneUI-500;
  .background-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(313deg, rgba(0, 0, 0, 0) 57.15%, rgba(0, 0, 0, 0.99) 85.94%);
    // opacity: 0;
    transition: opacity 300ms linear;
    &.visible {
      opacity: 1;
    }
    &.hide {
      opacity: 0;
    }
  }
  .channel-drawer {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 11;
    color: #fff;
    opacity: 1;
    transition: all 700ms cubic-bezier(0.32, 1.35, 0.3, 1);
    // &.visible {
    //   background: linear-gradient(313deg, rgba(0, 0, 0, 0) 57.15%, rgba(0, 0, 0, 0.99) 85.94%);
    // }
    &.pgbg {
      background: linear-gradient(314deg, rgba(0, 0, 0, 0) 54.83%, rgba(0, 0, 0, 0.6) 81.12%);
    }
    .channel-container {
      position: absolute;
      width: 100%;
      height: 520 * $s;
      top: 100%;
      border-radius: 16 * $s 16 * $s 0 0;
      border-top: 1 * $s solid rgba(255, 255, 255, 0.5);
      background: linear-gradient(rgba(27, 27, 27, 0.9), rgb(20, 20, 20, 0.96));
      transition: all 300ms cubic-bezier(0.15, 1, 0.35, 1);
      &.visible {
        transform: translateY(-520 * $s);
      }
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32 * $s 48 * $s 0 48 * $s;
        .samsung-news-logo {
          position: relative;
          width: 260 * $s;
          height: 28 * $s;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .geo-info {
          text-align: right;
          .current-time {
            font-size: 28 * $s;
            font-weight: 700;
            line-height: 48 * $s;
          }
          .weather {
            height: 48 * $s;
            display: flex;
            align-items: center;
            // gap: 8 * $s;
            .temparature {
              font-size: 28 * $s;
              font-weight: 700;
              line-height: 48 * $s;
              margin: 0 8 * $s 0 8 * $s;
            }
            .location {
              font-size: 20 * $s;
              font-weight: 500;
              line-height: 28 * $s;
            }
            .weather-icon {
              width: 50 * $s;
              height: 42 * $s;
              img {
                width: 38 * $s;
                height: 42 * $s;
              }
            }
          }
        }
      }

      .channel-list {
        position: absolute;
        display: flex;
        height: 312 * $s;
        top: 168 * $s;
        left: 40 * $s;
        padding: 0;
        margin: 0;
        transition: transform 683ms cubic-bezier(0.5, 1, 0.3, 1);
        list-style-type: none;
        .listItem {
          margin-right: 24 * $s; // gap between tiles is 32 = 24 + 8 border space
          .channel-logo {
            width: $img_w + $border_w;
            height: $img_h + $border_w;
            border-radius: 10 * $s;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .logo {
              background-color: #d0d0d1;
              position: relative;
              width: $img_w;
              height: $img_h;
              border-radius: 8 * $s;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: inherit;
                transition: opacity 300ms cubic-bezier(0.15, 1, 0.35, 1);
                transition: opacity 150ms cubic-bezier(0.15, 1, 0.35, 1) 750ms;
                transition: opacity 500ms linear 20ms;
                opacity: 0;
                &.hide-icon {
                  opacity: 0;
                  transition: opacity 150ms cubic-bezier(0.15, 1, 0.35, 1) 750ms;
                  transition: opacity 500ms linear 750ms;
                }
                &.show-icon {
                  opacity: 1;
                  transition: opacity 300ms cubic-bezier(0.15, 1, 0.35, 1);
                }
                &.hide-prog {
                  opacity: 0;
                }
                &.channel-icon {
                  width: $img_h - 48 * $s;
                  height: $img_h - 48 * $s;
                  padding: 24 * $s 0;
                }
                &.hidefocus {
                  opacity: 1 !important;
                  transition: opacity 500ms linear 750ms;
                }
              }
              .now-playing {
                position: absolute;
                width: 100%;
                height: 100%;
                font-size: 28 * $s;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6 * $s;
                background: rgba(0, 0, 0, 0.8);
                transition: all 3000ms cubic-bezier(0.15, 1, 0.35, 1);
                z-index: 1;
              }
              .progress-bar {
                position: absolute;
                width: $img_w;
                height: 8 * $s;
                bottom: 12 * $s;
                left: 0 * $s;
                top: $img_h - 8 * $s;
                border-radius: 0 0 8 * $s 8 * $s;
                overflow: hidden;
                .bar {
                  position: absolute;
                  width: 100%;
                  height: 8 * $s;
                  background-color: rgba(255, 255, 255, 0.7);
                }
                .bar-fill {
                  position: absolute;
                  height: 8 * $s;
                  background: #f75157;
                }
              }
            }
          }
          .channel-info {
            width: $img_w;
            height: 84 * $s;
            color: #fff;
            padding-left: 8 * $s;
            .title {
              width: 100%;
              height: 48 * $s;
              line-height: 48 * $s;
              font-family: SamsungOneUI-700;
              font-size: 28 * $s;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: inline-block;
            }
            .duration {
              line-height: 28 * $s;
              font-weight: 500;
              font-size: 20 * $s;
            }
          }
          &.focus .channel-logo {
            animation: channel-focus-in 300ms cubic-bezier(0.21, 1.5, 0.54, 1) forwards;
          }
          &.focus .channel-info .title {
          }

          .channel-logo {
            animation: channel-focus-out 300ms cubic-bezier(0.15, 1, 0.35, 1) forwards;
          }
        }
      }
    }
  }
}

@keyframes channel-focus-in {
  0% {
    background-color: unset;
  }
  100% {
    background-color: #fff;
  }
}
@keyframes channel-focus-out {
  0% {
    background-color: #fff;
  }
  100% {
    background-color: unset;
  }
}
